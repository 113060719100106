import React from "react";
import { useForm } from "react-hook-form";
import Image from "../../asset/img/Logo/logo.png";
import Image2 from "../../asset/img/SingleProduct/1.png";
import EmptyCart from "../../asset/img/Order/cart.png";
import { useCartContext } from "../Context/UserContext";
import { Link } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import Button from "../Common/Button";
import Footer2 from "../Common/Footer/index2";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const Finalpayment = () => {
  const { cart, setCart } = useCartContext();

  let size = cart.length;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    // Combine form data with cart details
    const payload = {
      ...data,
      user_total_amount: total,
    };
    console.log("Payload", payload); // Debugging purpose, remove before production use

    try {
      const response = await axios.post(
        "https://api.gauswarn.com/users/create-order",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        // Success message
          window?.open(response?.data?.url)
        toast.success("Order placed successfully!");

        // Reset cart and form
        setCart([]);
        reset();
      } else {
        // Handle unexpected response status
        toast.error("Something went wrong. Please try again.");
      }
    } catch (error) {
      console.error(
        "Error during order submission:",
        error.response?.data || error.message
      );
      toast.error("Failed to place the order. Please try again later.");
    }
  };

  const subtotal = cart.reduce(
    (acc, item) => acc + item.product_price * item.product_quantity,
    0
  );
  const total = subtotal;

  // removeFromCart
  const removeFromCart = async (product_id) => {
    console.log("Removing", product_id);
    try {
      // Send API request to remove the item from the server cart
      const response = await axios.request({
        method: "DELETE",
        url: "https://api.gauswarn.com/users/login/removecart",
        data: { product_id }, // Send product_id in the request body
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        // If successful, update the cart state
        const updatedCart = cart.filter(
          (item) => item.product_id !== product_id
        );
        setCart(updatedCart);
        toast.success("Item removed from cart successfully!");
      } else {
        toast.error("Failed to remove item from cart. Please try again.");
      }
    } catch (error) {
      console.error(
        "Error removing item from cart:",
        error.response?.data || error.message
      );
      toast.error("Something went wrong. Please try again later.");
    }
  };

  // const updateQuantity = (index, change) => {
  //   console.log(cart,"cary")
  //   setCart((prevCart) => {
  //     const updatedCart = [...prevCart];

  //     console.log(updatedCart,12323)
  //     const newQuantity = updatedCart[index].product_quantity + change;
  //     if (newQuantity < 1) return prevCart; // Prevent negative or zero quantities
  //     updatedCart[index].product_quantity = newQuantity;
  //     return updatedCart;
  //   });
  // };
  const updateQuantity = (index, change) => {
    setCart((prevCart) => {
      return prevCart.map((item, i) => {
        if (i === index) {
          const newQuantity = item.product_quantity + change;
          return {
            ...item,
            product_quantity: Math.max(newQuantity, 1), // Ensure quantity is at least 1
          };
        }
        return item; // Keep other items unchanged
      });
    });
  };

  const calculateTotalQuantity = () => {
    return cart.reduce((acc, item) => acc + item.product_quantity, 0);
  };
  const Iteam = calculateTotalQuantity();
  console.log("calculating total quantity");
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick
        pauseOnHover
      />
      <div className="final-pay">
        <div className="container-fluid">
          {size === 0 ? (
            <div className="py-5">
              <div>
                <h3 className="text-center">- : Your Basket is Empty : -</h3>
              </div>
              <div className="d-flex justify-content-center my-5">
                <h5>
                  {/* <FontAwesomeIcon icon={faCartShopping} /> */}
                  <img
                    src={EmptyCart}
                    alt="Loading"
                    className="rounded mx-auto d-block w-75"
                  />
                </h5>
              </div>
              <Link to="/singleproduct" className="text-center d-block mt-3">
                {/* <button className="btn btn-primary">Go Back to Shopping</button> */}
                <Button title="Go Back to Shopping" />
              </Link>
            </div>
          ) : (
            <>
              <div className="row">
                <div className="col-lg-6 col-md-12 py-5 background-color-pullman-green">
                  <div className="order-input px-4">
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className="p-4 border rounded-3 lh-lg"
                      style={{
                        maxWidth: "600px",
                        margin: "0 auto",
                      }}
                    >
                      <label className="text-white fw-bold">E-MAIL</label>
                      <input
                        type="email"
                        className="input w-100 px-3 py-1 border rounded-3"
                        {...register("user_email", { required: true })}
                        placeholder="E-MAIL"
                      />
                      {errors.user_email && (
                        <p className="text-light mb-0">
                          This field is required
                        </p>
                      )}

                      <label className="text-white fw-bold">DELIVERY</label>
                      <input
                        className="w-100 px-3 py-1 border rounded-3"
                        {...register("user_country", { required: true })}
                        placeholder="COUNTRY"
                      />
                      {errors.user_country && (
                        <p className="text-light mb-0">
                          This field is required
                        </p>
                      )}

                      <input
                        className="w-100 mt-2 px-3 py-1 border rounded-3"
                        {...register("user_name", { required: true })}
                        placeholder="FULL NAME"
                      />
                      {errors.user_name && (
                        <p className="text-light mb-0">
                          This field is required
                        </p>
                      )}

                      <input
                        className="w-100 mt-2 px-3 py-1 border rounded-3"
                        {...register("user_house_number", { required: true })}
                        placeholder="HOUSE NO. / COLONY"
                      />
                      {errors.user_house_number && (
                        <p className="text-light mb-0">
                          This field is required
                        </p>
                      )}

                      <input
                        className="w-100 mt-2 px-3 py-1 border rounded-3"
                        {...register("user_landmark", { required: true })}
                        placeholder="STREET / LANDMARK"
                      />
                      {errors.user_landmark && (
                        <p className="text-light mb-0">
                          This field is required
                        </p>
                      )}
                      <div className="row d-flex justify-content-between">
                        <div className="col-lg-5 col-md-12">
                          <input
                            className="city-input mt-2 px-3 py-1 border rounded-3"
                            {...register("user_city", { required: true })}
                            placeholder="CITY"
                          />
                          {errors.user_city && (
                            <p className="text-light mb-0">
                              This field is required
                            </p>
                          )}
                        </div>
                        <div className="col-lg-5 col-md-12">
                          <input
                            className="state-input mt-2 px-3 py-1 border rounded-3"
                            {...register("user_state", { required: true })}
                            placeholder="STATE"
                          />
                          {errors.user_state && (
                            <p className="text-light mb-0">
                              This field is required
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="row d-flex justify-content-between">
                        <div className="col-lg-5 col-md-12">
                          <input
                            className="pin-input mt-2 px-3 py-1 border rounded-3"
                            type="number"
                            {...register("user_pincode", { required: true })}
                            placeholder="PIN"
                          />
                          {errors.user_pincode && (
                            <p className="text-light mb-0">
                              This field is required
                            </p>
                          )}
                        </div>
                        <div className="col-lg-5 col-md-12">
                          <input
                            className="mob-input mt-2 px-3 py-1 border rounded-3"
                            type="tel"
                            {...register("user_mobile_num", { required: true })}
                            placeholder="MOB NO."
                          />
                          {errors.user_mobile_num && (
                            <p className="text-light mb-0">
                              This field is required
                            </p>
                          )}
                        </div>
                      </div>
                      {/* <label className="text-white mt-3 w-100">
                        <b>PAYMENT</b> secure and encrypted payment
                      </label> */}
                      {/* <div className="form-check d-flex align-items-center">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                        />
                        <label
                          className="form-check-label text-white ms-2"
                          htmlFor="flexRadioDefault1"
                        >
                          Razorpay Secure (UPI, Cards, Wallet, NetBanking)
                        </label>
                      </div> */}
                      <div className="d-flex align-items-center justify-content-center pt-4">
                        <button
                          type="submit"
                          className="w-50 border rounded-pill text-uppercase button-feedback"
                        >
                          Pay Now
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="final-price">
                    <div className="order-logo m-auto">
                      <img src={Image} alt="Product" className="w-100 h-100" />
                    </div>

                    {/* <div className="product-table mt-5">
                      <table className="table table-striped table-bordered">
                        <tbody>
                          {cart.map((item, index) => (
                            <tr key={index}>
                              <td className="text-center">
                                <img
                                  src={item.image || Image2}
                                  alt="Product"
                                  style={{ width: "60px", height: "60px" }}
                                />
                              </td>
                              <td className="text-center">
                                A2 Gir Cow Ghee ({item.liter})
                              </td>
                              <td>
                                <div className="quantity-controls d-flex align-items-center justify-content-center">
                                  <button
                                    className="btn btn-outline-secondary btn-sm"
                                    onClick={() => updateQuantity(index, -1)}
                                    disabled={item.product_quantity <= 1}
                                  >
                                    -
                                  </button>
                                  <span className="mx-3">{item.product_quantity}</span>
                                  <button
                                    className="btn btn-outline-secondary btn-sm"
                                    onClick={() => updateQuantity(index, 1)}
                                  >
                                    +
                                  </button>
                                </div>
                              </td>
                              <td className="text-center">
                                ₹ {item.price * item.product_quantity}
                              </td>
                              <td className="text-center">
                                <button
                                  className="btn btn-danger btn-sm m-auto"
                                  onClick={() => removeFromCart(item.product_id)}
                                >
                                  <MdDelete />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div> */}
                    <div className="product-table mt-5">
                      <table className="table table-striped table-bordered text-center">
                        <tbody>
                          {cart.map((item, index) => (
                            <tr key={index} className="align-middle">
                              <td>
                                <img
                                  src={item.image || Image2}
                                  alt="Product"
                                  style={{ width: "60px", height: "60px" }}
                                />
                              </td>
                              <td>A2 Gir Cow Ghee ({item.product_weight})</td>
                              <td>
                                <div className="quantity-controls d-flex align-items-center justify-content-center">
                                  <button
                                    className="btn btn-outline-secondary btn-sm"
                                    onClick={() => updateQuantity(index, -1)}
                                    disabled={item.product_quantity <= 1}
                                  >
                                    -
                                  </button>
                                  <span className="mx-3">
                                    {item.product_quantity}
                                  </span>
                                  <button
                                    className="btn btn-outline-secondary btn-sm"
                                    onClick={() => updateQuantity(index, 1)}
                                  >
                                    +
                                  </button>
                                </div>
                              </td>
                              <td>₹ {item.product_price}</td>
                              <td>
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={() =>
                                    removeFromCart(item.product_id)
                                  }
                                >
                                  <MdDelete />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-between mt-1">
                      <p className="fs-5 ms-4">Subtotal :-</p>
                      <p className="fw-normal me-4">₹{subtotal}</p>
                    </div>
                    <div className="d-flex justify-content-between mt-1">
                      <p className="fs-5 ms-4"> Item:-</p>
                      <p className="fw-normal me-4">{Iteam}</p>
                    </div>
                    <div className="d-flex justify-content-between lh-1 mt-1">
                      <p className="fs-5 ms-4">Shipping Charges</p>
                      <p className="fw-normal me-4">Free</p>
                    </div>
                    <div className="d-flex justify-content-between lh-1 mt-1">
                      <p className="fs-5 fw-bold ms-4">Total</p>
                      <p className="fw-normal me-4">₹{total}</p>
                    </div>
                    <p className="lh-1 ms-4">
                      (Including all services and taxes)
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer2 />
    </>
  );
};

export default Finalpayment;
