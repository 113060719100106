import React from "react";

const Process = () => {
  return (
    <>
      <div className="row">
          <div class="col-lg-12 process py-5">
            <h1 className="d-flex justify-content-center text-center process-text"><strong> How GAUSWARN Prepare A2 GIR Cow Ghee </strong></h1>
            <h2 className="text-center process-text">Using Bilona Method</h2>
            <div className="processImg"></div>
          </div>
      </div>
    </>
  );
};
export default Process;
