import React, { useEffect } from "react";
import Footer2 from "../Common/Footer/index2";
import { useNavigate } from "react-router-dom";
import { useCartContext } from "../Context/UserContext";

const PaymentSuccess = () => {
  const { setCart } = useCartContext();

  const navigate = useNavigate();
  const sendInvoice = async () => {
    try {
      const response = await fetch(
        "http://bhashsms.com/api/sendmsg.php?user=BhashWapAi&pass=Bwa@123&sender=BUZWAP&phone=7224851233&text=bsl_image&priority=wa&stype=normal&htype=image&url=https://i.ibb.co/7XRmyh9/bhash-logo.png",
        { mode: "no-cors" }
      );
      console.log("response: ", response);
      navigate("/");
    } catch (error) {
      console.error("Error sending invoice:", error);
    }
  };

  useEffect(() => {
    // Clear data from localStorage when this component loads
    localStorage.removeItem("cart");
    console.log("Cart data cleared from localStorage.");
    setCart([]);
    
  }, []); // Empty dependency array ensures this runs only once when the component mounts


  return (
    <div>
      <div className="payment border border-black d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div class="success-animation py-2">
                <svg
                  class="checkmark"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52"
                >
                  <circle
                    class="checkmark__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                  />
                  <path
                    class="checkmark__check"
                    fill="none"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                  />
                </svg>
              </div>
              <div className="text-center py-2">
                <p className="fs-2 fw-bold">Payment Success full</p>
                <button
                  onClick={sendInvoice}
                  className="px-5 py-2 fw-bold border rounded-pill bg-success text-light"
                >
                  Print
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </div>
  );
};

export default PaymentSuccess;
