import React from "react";
import Home from "./Home";
import AboutUs from "./About";
import Processed from "./Processed";
import GheeComparison from "./GheeComparison";
import WhatSetsUsApart from "./WhatSetsUsApart";
import Client from "./Client";
import Certified from "./Certified";
import Footer from "../Common/Footer/index";
import GirCowInfo from "./GirCowInfo";
import Benefit from "../Pages/Gheebenefit";
// import Video from "../Pages/Video";
import Process from "./Process";
// import Payment from "./PaymentDone";
// import PaymentFail from "./PaymentFail";


const Main = () => {
  return (
    <div>
      <Home />
      <AboutUs />
      {/* <Payment /> */}
      {/* <PaymentFail /> */}
      <GirCowInfo />
      {/* <GheeLabel /> */}
      {/* <Video/> */}
      <Processed />
      <Benefit />
      <Process />
      <GheeComparison />
      <WhatSetsUsApart />
      <Client />
      <Certified />
      <Footer/>
    </div>
  );
};

export default Main;
