import "./App.css";

import Main from "./Component/Pages/Main";
import { Routes, Route } from "react-router-dom";
import SingleProcess from "./Component/Pages/SingleProcessed.jsx";
import Finalpayment from "./Component/Pages/Finalpayment.jsx";
import CustomNavbar from "./Component/Common/Navbar/index.jsx";
// import Footer from "./Component/Common/Footer/index.jsx";
import ScrollToTop from "./Component/Common/Scroll-to-Top/index.jsx";
import Contact from "./Component/Pages/Contact.jsx";
import Refund from "./Component/Pages/Refund.jsx";
import Cancel from "./Component/Pages/Cancel.jsx";
import Privacy from "./Component/Pages/Privacy.jsx";
import Shipping from "./Component/Pages/Shipping.jsx";
import Lab from "./Component/Pages/Lab.jsx";
import Faq from "./Component/Pages/Faq.jsx";
import Loader from "./Component/Common/Loader/loader.jsx";
import TrackingOrder from "./Component/Pages/TrackOrder.jsx";
import FeedbackForm from "./Component/Pages/Feedback.jsx";

import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import TermsConditions from "./Component/Pages/Terms&Conditions.jsx";
import PaymentDone from "./Component/Pages/PaymentSuccess.jsx";
import PaymentFail from "./Component/Pages/PaymentFailed.jsx";
import { useCartContext } from "./Component/Context/UserContext.jsx";

function App() {
  const { setCart } = useCartContext();

  useEffect(() => {
    AOS.init({
      offset: 100, // Offset to the element when scrolling (in pixels)
      duration: 1000, // Animation duration in milliseconds
      easing: "ease-in-out", // Easing pattern (see https://easings.io/)
      once: false, // Whether animation should happen only once
      mirror: true, // Mirror the animation when element is above the viewport (true or false)
      anchorPlacement: "top-bottom", // Placement of the element (top, bottom, left, right, top-left, top-right, bottom-left, bottom-right)
    });
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  setTimeout(() => {
    setIsLoading(false);
  }, 3000);

  useEffect(() => {
    const storedCart = localStorage.getItem("cart");
    if (storedCart) {
      setCart(JSON.parse(storedCart));
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <Routes>
          <Route path="/" element={<Loader />} />
        </Routes>
      ) : (
        <>
          <ScrollToTop />
          <CustomNavbar />
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/singleproduct" element={<SingleProcess />} />
            <Route path="/finalpayment" element={<Finalpayment />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/refund" element={<Refund />} />
            <Route path="/cancel" element={<Cancel />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/shipping" element={<Shipping />} />
            <Route path="/lab" element={<Lab />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/track" element={<TrackingOrder />} />
            <Route path="/feedback" element={<FeedbackForm />} />
            <Route path="/terms" element={<TermsConditions />} />
            <Route path="/payment-success" element={<PaymentDone />} />
            <Route path="/payment-failed" element={<PaymentFail />} />



          </Routes>
          {/* <Footer /> */}
        </>
      )}
    </>
  );
}

export default App;
