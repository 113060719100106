// // import React, { useState, useEffect } from "react";
// // // import CustomNavbar from "../../Component/Common/Navbar";
// // import jarImage1 from "../../asset/img/Home/4.png";
// // import jarImage2 from "../../asset/img/Home/5.png";
// // import jarImage3 from "../../asset/img/Home/6.png";
// // import jarImage4 from "../../asset/img/Home/image 7.png";
// // import jarImage5 from "../../asset/img/Home/image 8.png";
// // import jarImage6 from "../../asset/img/Home/image 6.png";
// // import jarImage7 from "../../asset/img/Home/2.png";
// // import jarImage8 from "../../asset/img/Home/3.png";
// // import jarImage9 from "../../asset/img/Home/jar.png";
// // import jar from "../../asset/img/Home/jar.png";
// // import Button from "../Common/Button";

// // const Home = () => {
// //   // Array of image groups
// //   const imageGroups = [
// //     [jarImage1, jarImage2, jarImage3],
// //     [jarImage4, jarImage5, jarImage6],
// //     [jarImage7, jarImage8, jarImage9],
// //   ];

// //   // State to track the current group index
// //   const [currentGroup, setCurrentGroup] = useState(0);

// //   useEffect(() => {
// //     // Timer to rotate groups every 3 seconds
// //     const interval = setInterval(() => {
// //       setCurrentGroup((prevGroup) => (prevGroup + 1) % imageGroups.length);
// //     }, 3000); // Change every 3 seconds

// //     // Clear interval on component unmount
// //     return () => clearInterval(interval);
// //   }, [imageGroups.length]);

// //   return (
// //     <div id="home" className="home">
// //       <div className="container">
// //         <div className="row align-items-center">
// //           {/* Left Section */}
// //           <div
// //             className="col-lg-6 text-section"
// //             data-aos="fade-right"
// //             data-aos-easing="linear"
// //             data-aos-duration="1500"
// //           >
// //             <p className="pure-text">100% Pure</p>
// //             <h1>
// //               Make Your Dish More <br />
// //               <span className="highlight-text fw-bold">
// //                 Healthy With A2 <br />
// //                 GIR <b className="text-color-green"> Cow Ghee</b>
// //               </span>
// //             </h1>
// //             <p className="description-text fs-6 fw-bold">
// //               Lorem Ipsum Is Simply Dummy Text Of The Printing And <br />{" "}
// //               Typesetting Industry.
// //             </p>
// //             {/* <button className="shop-button">Shop Now</button> */}
// //             <Button title="Shop Now" />
// //           </div>

// //           {/* Right Section */}
// //           <div
// //             className="col-lg-6 mt-4 ps-5"
// //             data-aos="fade-left"
// //             data-aos-easing="linear"
// //             data-aos-duration="1500"
// //           >
// //             <div className="jar-container">
// //               {/* Rotating Images */}
// //               <div className="food-images">
// //                 {imageGroups[currentGroup].map((image, index) => (
// //                   <img
// //                     key={index}
// //                     src={image}
// //                     alt={`Food ${index + 1}`}
// //                     className={`food-image food-${index + 1}`}
// //                   />
// //                 ))}
// //               </div>
// //               <div className="jar-image">
// //                 <img src={jar} alt="Ghee Jar" className="main-jar" />
// //                 <div className="back-circle"></div>
// //               </div>
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default Home;





// import React from "react";
// import Image1 from "../../asset/img/Home/35.png";
// import Image2 from "../../asset/img/Home/36.png";
// import Image3 from "../../asset/img/Home/37.png";
// import Image4 from "../../asset/img/Home/38.png";


// const Home = () => {
//   return (
//     <>
//       <div className="home">
//         <div className="row">
//           <div
//             id="carouselExampleControls"
//             className="carousel slide col-lg-12 col-md-12"
//             data-bs-ride="carousel"
//           >
//             <div className="carousel-inner">
//               <div className="carousel-item active" data-bs-interval="3000">
//                 <a href="#" >
//                   <img src={Image1} className="d-block w-100" alt="Slide 1" />
//                 </a>
//               </div>
//               <div className="carousel-item" data-bs-interval="3000">
//                 <img src={Image2} className="d-block w-100" alt="Slide 2" />
//               </div>
//               <div className="carousel-item" data-bs-interval="3000">
//                 <img src={Image3} className="d-block w-100" alt="Slide 3" />
//               </div>
//               <div className="carousel-item" data-bs-interval="3000">
//                 <img src={Image4} className="d-block w-100" alt="Slide 3" />
//               </div>
//               {/* <div className="carousel-item" data-bs-interval="3000">
//                 <img src={Image5} className="d-block w-100" alt="Slide 3" />
//               </div> */}
//             </div>
//             <button
//               class="carousel-control-prev"
//               type="button"
//               data-bs-target="#carouselExampleControls"
//               data-bs-slide="prev"
//             >
//               <span
//                 class="carousel-control-prev-icon"
//                 aria-hidden="true"
//               ></span>
//               <span class="visually-hidden">Previous</span>
//             </button>
//             <button
//               class="carousel-control-next"
//               type="button"
//               data-bs-target="#carouselExampleControls"
//               data-bs-slide="next"
//             >
//               <span
//                 class="carousel-control-next-icon"
//                 aria-hidden="true"
//               ></span>
//               <span class="visually-hidden">Next</span>
//             </button>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Home;



import React from "react";
// import CustomNavbar from "../Common/Navbar/index";
 
const Home = () => {
  return (
    <>
   
      {/* <CustomNavbar /> */}
      <div className="home">
        <div className="row">
         
          <div
            id="carouselExampleControls"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div
                className="carousel-item active slide-1"
                data-bs-interval="2000"
              ></div>
              <div
                className="carousel-item slide-2"
                data-bs-interval="2000"
              ></div>
              <div
                className="carousel-item slide-3"
                data-bs-interval="2000"
              ></div>
              <div
                className="carousel-item slide-4"
                data-bs-interval="2000"
              ></div>
            </div>
 
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
 
export default Home;