// // import React, { useEffect, useState } from "react";
// // import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap CSS is imported
// // import Image1 from "../../asset/img/benefits of ghee/benefit 1.png";
// // import Image2 from "../../asset/img/benefits of ghee/benefit 2.png";
// // import Image3 from "../../asset/img/benefits of ghee/benefit 3.png";
// // import Image4 from "../../asset/img/benefits of ghee/benefit 4.png";
// // import Image5 from "../../asset/img/benefits of ghee/benefit 5.png";
// // import Image6 from "../../asset/img/benefits of ghee/benefit 6.png";
// // import Image7 from "../../asset/img/benefits of ghee/benefit 7.png";
// // import Image8 from "../../asset/img/benefits of ghee/benefit 8.png";
// // import Image9 from "../../asset/img/benefits of ghee/benefit 9.png";
// // import Image10 from "../../asset/img/benefits of ghee/benefit 10.png";
// // import Image11 from "../../asset/img/benefits of ghee/benefit 11.png";
// // import Image12 from "../../asset/img/benefits of ghee/benefit 12.png";

// // const CutoutSlider = () => {
// //   // Array of images
// //   const images = [
// //     Image1,
// //     Image2,
// //     Image3,
// //     Image4,
// //     Image5,
// //     Image6,
// //     Image7,
// //     Image8,
// //     Image9,
// //     Image10,
// //     Image11,
// //     Image12,
// //   ];

// //   // State to control visibility of each card
// //   const [showCards, setShowCards] = useState(Array(12).fill(false)); // Array to manage visibility of 12 cards

// //   console.log("Slider", showCards);
// //   useEffect(() => {
// //     // Function to start the display sequence
// //     const startSequence = () => {
// //       const sequenceTimers = [];
// //       console.log("Sequence", sequenceTimers);
// //       const cardCount = showCards.length;

// //       // Show each cutout card in sequence with delay
// //       // for (let i = 0; i < cardCount; i++) {
// //       //   sequenceTimers.push(
// //       //     setTimeout(() => {
// //       //       setShowCards((prev) => {
// //       //         const newCards = [...prev];

// //       //         newCards[i] = true;
// //       //         return newCards;
// //       //       });
// //       //       // As soon as five images are stored in previous, make it false.
// //       //       // Solution : -

// //       //       if (i % 5 === 4) {
// //       //         setShowCards((prev) => {
// //       //           const newCards = [...prev];

// //       //           newCards.fill(false);
// //       //           return newCards;
// //       //         });
// //       //       }

// //       //     }, i * 2000)
// //       //   );
// //       // }

// //       for (let i = 0; i < cardCount; i++) {
// //         sequenceTimers.push(
// //           setTimeout(() => {
// //             setShowCards((prev) => {
// //               const newCards = [...prev];
// //               newCards[i] = true; // Show current image
// //               return newCards;
// //             });

// //             // Check if we've reached the end of a group of 5 images or the end of the list
// //             if ((i + 1) % 5 === 4 || i === cardCount - 1) {
// //               // Set a delay to hide all images after 2 seconds
// //               setTimeout(() => {
// //                 setShowCards(Array(cardCount).fill(false)); // Hide all images
// //               }, 2000);
// //             }
// //           }, i * 2000) // Show each image with a 2-second delay
// //         );
// //       }

// //       // Hide all cards after showing them
// //       const hideAllTimer = setTimeout(() => {
// //         setShowCards(Array(cardCount).fill(false)); // Hide all cards
// //         startSequence(); // Restart sequence
// //       }, cardCount * 2000 + 2000); // Hide after all cards are shown

// //       // Clear timers on component unmount
// //       return () => {
// //         sequenceTimers.forEach((timer) => clearTimeout(timer));
// //         clearTimeout(hideAllTimer);
// //       };
// //     };

// //     // Start the sequence when component mounts
// //     startSequence();
// //   }, []);

// //   return (
// //     <div className="benefit py-5">
// //       <div className="container">
// //         <div className="row">
// //           {/* <div className="col-lg-3 py-5">
// //             <div className="benefit-logo">
// //               <img
// //                 src={Image}
// //                 alt="GAUSWARN A2 Ghee Logo"
// //                 className="w-75 h-100"
// //               />
// //             </div>
// //           </div> */}
// //           <div className="col-lg-12 pb-5">
// //             <div className="benefit-heading">
// //               <h1 className="text-color-pullman-green text-center">
// //                 Benefits of A2 Gir Cow Ghee
// //               </h1>
// //             </div>
// //           </div>
// //           {/* Conditional rendering for each cutout */}
// //           {showCards.map((showCard, index) => (
// //             <div
// //               className={`col-md-3 col-sm-6 pb-4 ${
// //                 showCard ? "slide-in d-flex justify-content-center align-items-center" : "d-none"
// //               }`}
// //               key={index}
// //             >
// //               <img
// //                 src={images[index]}
// //                 className="w-75"
// //                 alt={`Cutout ${index + 1}`}
// //               />
// //               {/* <div className="card-body">
// //                 <h5 className="card-title">{`Cutout ${index + 1}`}</h5>
// //                 <p className="card-text">This is cutout number {index + 1}.</p>
// //               </div> */}
// //             </div>
// //           ))}
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default CutoutSlider;

// // import React from "react";
// // import Leaf from "../../asset/img/benefits of ghee/Plant Image.png";
// // import CowImg from "../../asset/img/benefits of ghee/Cow Image.png";

// // const Gheebenefit = () => {
// //   return (
// //     <>
// //       <React.Fragment>
// //         <div className="background-color-pullman-green position-relative" style={{height:"100vh"}}>
// //           <img
// //             src={Leaf}
// //             alt="Leaf"
// //             className="w-25 position-absolute start-0"
// //           />
// //           <div className="container">
// //             <div className="text-center text-color-eggshell font-size-27 font-family-roboto py-3">
// //               Gauswarn A2 Ghee
// //             </div>

// //           <div className="row g-4 pt-5">
// //             {/* Grid Item 1 */}
// //             <div className="col-12 col-sm-6 col-lg-6 d-flex justify-content-end">
// //               <div className="grid-item text-center">
// //                 <i className="bi bi-basket"></i>
// //                 <p>High-Quality Ingredients</p>
// //               </div>
// //             </div>
// //             {/* Grid Item 2 */}
// //             <div className="col-12 col-sm-6 col-lg-6">
// //               <div className="grid-item text-center">
// //                 <i className="bi bi-droplet-half"></i>
// //                 <p>Pure and Natural</p>
// //               </div>
// //             </div>
// //             {/* Grid Item 3 */}
// //             <div className="col-12 col-sm-6 col-lg-6  d-flex justify-content-end">
// //               <div className="grid-item text-center">
// //                 <i className="bi bi-heart"></i>
// //                 <p>Healthy for the Heart</p>
// //               </div>
// //             </div>
// //             {/* Grid Item 4 */}
// //             <div className="col-12 col-sm-6 col-lg-6">
// //               <div className="grid-item text-center">
// //                 <i className="bi bi-tree"></i>
// //                 <p>Eco-Friendly Process</p>
// //               </div>
// //             </div>
// //             {/* Grid Item 5 */}
// //             <div className="col-12 col-sm-6 col-lg-6  d-flex justify-content-end">
// //               <div className="grid-item text-center">
// //                 <i className="bi bi-star"></i>
// //                 <p>Rich in Flavor</p>
// //               </div>
// //             </div>
// //             {/* Grid Item 6 */}
// //             <div className="col-12 col-sm-6 col-lg-6">
// //               <div className="grid-item text-center">
// //                 <i className="bi bi-lightning"></i>
// //                 <p>Energy Booster</p>
// //               </div>
// //             </div>
// //           </div>
// //           </div>
// //           <img
// //             src={CowImg}
// //             alt="Leaf"
// //             className="w-25 position-absolute end-0"
// //             style={{ top: "67%" }}
// //           />
// //         </div>
// //       </React.Fragment>
// //     </>
// //   );
// // };

// // export default Gheebenefit;

// import React from "react";
// import Leaf from "../../asset/img/benefits of ghee/Plant Image.png";
// import CowImg from "../../asset/img/benefits of ghee/Cow Image.png";
// import { CiShoppingBasket } from "react-icons/ci";

// const Gheebenefit = () => {
//   // Array of benefits
//   const benefits = [
//     { icon: <CiShoppingBasket />, title: "High-Quality Ingredients" },
//     { icon: <CiShoppingBasket />, title: "Pure and Natural" },
//     { icon: <CiShoppingBasket />, title: "Healthy for the Heart" },
//     { icon: <CiShoppingBasket />, title: "Eco-Friendly Process" },
//     { icon: <CiShoppingBasket />, title: "Rich in Flavor" },
//     { icon: <CiShoppingBasket />, title: "Rich in Flavor" },
//   ];

//   return (
//     <>
//       <div
//         className="background-color-pullman-green position-relative"
//         style={{ height: "100vh" }}
//       >
//         <img
//           src={Leaf}
//           alt="Leaf"
//           className="w-25 position-absolute start-0"
//         />
//         <div className="container">
//           <div className="text-center text-color-eggshell font-size-27 font-family-roboto py-3">
//             Gauswarn A2 Ghee
//           </div>

//           <div className="row g-4 pt-5">
//             {benefits.map((benefit, index) => (
//               <div
//                 key={index}
//                 className={`col-12 col-sm-6 col-lg-6 ${
//                   index % 2 === 0 ? "d-flex justify-content-end" : ""
//                 }`}
//               >
//                 <div className="grid-item text-center">
//                   <div className="icon">{benefit.icon}</div>
//                   <p>{benefit.title}</p>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>

//         <img
//           src={CowImg}
//           alt="Cow"
//           className="w-25 position-absolute end-0"
//           style={{ top: "67%" }}
//         />
//       </div>
//     </>
//   );
// };

// export default Gheebenefit;

import React from "react";
// import Leaf from "../../asset/img/benefits of ghee/leaf image.png";
// import CowImg from "../../asset/img/benefits of ghee/Benifit Cow.png";
import Wave from "../../asset/img/benefits of ghee/wave2.png";
import Image1 from "../../asset/img/benefits of ghee/9.png";
import Image2 from "../../asset/img/benefits of ghee/10.png";
import Image3 from "../../asset/img/benefits of ghee/8.png";
import Image4 from "../../asset/img/benefits of ghee/6.png";
import Image5 from "../../asset/img/benefits of ghee/11.png";
import Image6 from "../../asset/img/benefits of ghee/7.png";
// import { CiShoppingBasket } from "react-icons/ci";

const Gheebenefit = () => {
  // Array of benefits
  const benefits = [
    { icon: Image1, title: "Lowers Bad Cholesterol" },
    { icon: Image2, title: "Powers The Brain" },
    { icon: Image3, title: "Promotes  Good Eyesight" },
    { icon: Image4, title: "Builds strong Immune System" },
    { icon: Image5, title: "Give strength to Bones" },
    { icon: Image6, title: "Good For Heart Health" },
  ];

  return (
    <>
      <img src={Wave} alt="" className="w-100" />

      <div className="background-color-pullman-green position-relative height-benefit pb-4">
        {/* Left Decoration */}
        {/* <img src={Leaf} alt="Leaf" className="w-25 benefit-leaf position-absolute start-0" /> */}

        {/* Content */}
        <div className="container">
          {/* Title */}
          <div className="text-center text-color-eggshell font-size-27 font-family-roboto py-3">
            Benefits Of Our GAUSWARN Gir Cow Ghee <br />(Vedic Bilona Method)
          </div>

          {/* Benefits Grid */}
          <div className="row g-4 pt-5">
            {benefits.map((benefit, index) => (
              <div
                key={index}
                className={` col-sm-6 col-lg-4 d-flex justify-content-center ${
                  index % 2 === 0 ? "d-flex justify-content-center" : ""
                }`}
              >
                <div className="grid-item text-center">
                  {/* Icon */}
                  <div className="icon m-auto mb-2">
                    <img src={benefit.icon} alt="" />
                  </div>
                  {/* Title */}
                  <p className="mb-0">{benefit.title}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Right Decoration */}
        {/* <img
          src={CowImg}
          alt="Cow"
          className="w-25 benefit-cow position-absolute end-0"
          style={{ bottom: "10px" }}
        /> */}
      </div>
    </>
  );
};

export default Gheebenefit;
